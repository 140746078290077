import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import "./assets/css/base.css";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";

Vue.config.productionTip = false;

import VueVideoPlayer from "vue-video-player";

// 引入样式
import "video.js/dist/video-js.css";
import "vue-video-player/src/custom-theme.css";
import scroll from "vue-seamless-scroll";
Vue.use(scroll);

// 使用组件
Vue.use(VueVideoPlayer);

Vue.use(ElementUI);

new Vue({
  router,
  render: (h) => h(App),
}).$mount("#app");
