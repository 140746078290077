import Vue from "vue";
import VueRouter from "vue-router";

// 解决element-ui菜单重复点击报错
const VueRouterPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(to) {
  return VueRouterPush.call(this, to).catch((err) => err);
};
Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: "/",
    component: () => import("../views/layout.vue"),
    children: [
      {
        path: "/",
        name: "home",
        component: () => import("../views/home/index.vue"),
      },
      // 工商社保
      {
        path: "/home/gssb",
        name: "gssb_service",
        component: () => import("../views/home/detail/gssb_service.vue"),
      },
      // 工商社保
      {
        path: "/home/zffw",
        name: "zffw_service",
        component: () => import("../views/home/detail/zffw_service.vue"),
      },
      // 企业营销
      {
        path: "/home/qyyx",
        name: "qyyx_service",
        component: () => import("../views/home/detail/qyyx_service.vue"),
      },
      {
        path: "/home/cjfw",
        name: "cjfw_service",
        component: () => import("../views/home/detail/cjfw_service.vue"),
      },
      // 基础服务
      {
        path: "/enterpriseService",
        name: "enterpriseService",
        component: () => import("../views/enterpriseService/index.vue"),
      },
      // 财务服务 finance_1
      {
        path: "/finance_1",
        name: "finance_1",
        component: () => import("../views/finance_1/index.vue"),
      },
      // 代账服务
      {
        path: "/finance_1/detail_dzfw",
        name: "detail_dzfw",
        component: () => import("../views/finance_1/detail_dzfw.vue"),
      },
      // 数电票
      {
        path: "/finance_1/detail_sdp",
        name: "detail_sdp",
        component: () => import("../views/finance_1/detail_sdp.vue"),
      },
      // 电子凭证
      {
        path: "/finance_1/detail_dzpz",
        name: "detail_dzpz",
        component: () => import("../views/finance_1/detail_dzpz.vue"),
      },
      // 电子档案
      {
        path: "/finance_1/detail_dzda",
        name: "detail_dzda",
        component: () => import("../views/finance_1/detail_dzda.vue"),
      },
      // 金融服务 finance_2
      {
        path: "/finance_2",
        name: "finance_2",
        component: () => import("../views/finance_2/index.vue"),
      },
      {
        path: "/finance_2/loan_1",
        name: "loan_1",
        component: () => import("../views/finance_2/loan_ms.vue"),
      },
      // 数字化服务 digitalService
      {
        path: "/digitalService",
        name: "digitalService",
        component: () => import("../views/digitalService/index.vue"),
      },
      {
        path: "/digitalService/detail",
        name: "digitalServiceDetail",
        component: () => import("../views/digitalService/detail.vue"),
      },
      {
        path: "/digitalService/detail_pos",
        name: "detail_pos",
        component: () => import("../views/digitalService/detail_pos.vue"),
      },
      {
        path: "/digitalService/detail_jhm",
        name: "detail_jhm",
        component: () => import("../views/digitalService/detail_jhm.vue"),
      },
      // 批量收付款
      {
        path: "/digitalService/detail_sfk",
        name: "detail_sfk",
        component: () => import("../views/digitalService/detail_sfk.vue"),
      },
      // 代收代付
      {
        path: "/digitalService/detail_dsdf",
        name: "detail_dsdf",
        component: () => import("../views/digitalService/detail_dsdf.vue"),
      },
      // 收银机
      {
        path: "/digitalService/detail_syj",
        name: "detail_syj",
        component: () => import("../views/digitalService/detail_syj.vue"),
      },
      // 点单小程序
      {
        path: "/digitalService/detail_ddxcx",
        name: "detail_ddxcx",
        component: () => import("../views/digitalService/detail_ddxcx.vue"),
      },
      // 企业团餐
      {
        path: "/digitalService/detail_qytc",
        name: "detail_qytc",
        component: () => import("../views/digitalService/detail_qytc.vue"),
      },
      // k12
      {
        path: "/digitalService/detail_k12",
        name: "detail_k12",
        component: () => import("../views/digitalService/detail_k12.vue"),
      },
      // 一号密函
      {
        path: "/digitalService/detail_yhmh",
        name: "detail_yhmh",
        component: () => import("../views/digitalService/detail_yhmh.vue"),
      },
      // 一号密巢
      {
        path: "/digitalService/detail_yhmc",
        name: "detail_yhmc",
        component: () => import("../views/digitalService/detail_yhmc.vue"),
      },
      // 要素验证
      {
        path: "/digitalService/detail_ysyz",
        name: "detail_ysyz",
        component: () => import("../views/digitalService/detail_ysyz.vue"),
      },
      // 工商信息验证
      {
        path: "/digitalService/detail_gsxxyz",
        name: "detail_gsxxyz",
        component: () => import("../views/digitalService/detail_gsxxyz.vue"),
      },
      // 合作案例 cases
      {
        path: "/cases",
        name: "cases",
        component: () => import("../views/cases/index.vue"),
      },
      {
        path: "/cases/detail",
        name: "casesDetail",
        component: () => import("../views/cases/detail.vue"),
      },
      // 合作伙伴 partners
      {
        path: "/partners",
        name: "partners",
        component: () => import("../views/partners/index.vue"),
      },
      // 关于我们
      {
        path: "/about",
        name: "about",
        component: () => import("../views/About.vue"),
      },
      {
        path: "/info",
        name: "info",
        component: () => import("../views/info/index.vue"),
      },
      // 活动列表
      {
        path: "/activity",
        name: "activity",
        component: () => import("../views/activity/index.vue"),
      },
      // 活动详情
      {
        path: "/activity/detail_hd",
        name: "detail_hd",
        component: () => import("../views/activity/detail_hd.vue"),
      },
      // 政策详情
      {
        path: "/activity/detail_zc",
        name: "detail_zc",
        component: () => import("../views/activity/detail_zc.vue"),
      },
      // 凭证归集
      {
        path: "/certificateCollection",
        name: "certificateCollection",
        component: () =>
          import("../views/home/certificateCollection/index.vue"),
      },

      // 工商变更服务
      {
        path: "/industry",
        name: "industry",
        component: () => import("../views/home/industry/index.vue"),
      },

      // 法律服务
      {
        path: "/lawService",
        name: "lawService",
        component: () => import("../views/home/lawService/index.vue"),
      },

      // 知识产权服务
      {
        path: "/learningService",
        name: "learningService",
        component: () => import("../views/home/learningService/index.vue"),
      },

      // 科技服务
      {
        path: "/scienceService",
        name: "scienceService",
        component: () => import("../views/home/scienceService/index.vue"),
      },
      // 营销服务
      {
        path: "/market",
        name: "market",
        component: () => import("../views/market/index.vue"),
      },
      // 系列
      {
        path: "/series",
        name: "series",
        component: () => import("../views/series/index.vue"),
      },
      // 系列
      {
        path: "/service_solution",
        name: "service_solution",
        component: () => import("../views/serviceSolution/index.vue"),
      },
      // 满意度评价
      {
        path: "/satisfaction",
        name: "satisfaction",
        component: () => import("../views/satisfaction/index.vue"),
      },
      // 税务咨询
      {
        path: "/consult",
        name: "consult",
        component: () => import("../views/consult/index.vue"),
      },
      // 财税直播间
      {
        path: "/live",
        name: "live",
        component: () => import("../views/live/index.vue"),
      },
      // 代理记账申请
      {
        path: "/applyfor",
        name: "applyfor",
        component: () => import("../views/applyfor/index.vue"),
      },
      // 代理记账申请表单
      {
        path: "/applyform",
        name: "applyform",
        component: () => import("../views/applyfor/applyform.vue"),
      },
      {
        path: "/uploadform",
        name: "uploadform",
        component: () => import("../views/applyfor/uploadform.vue"),
      },
      {
        path: "/success",
        name: "success",
        component: () => import("../views/applyfor/success.vue"),
      },
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      return {
        selector: to.hash,
      };
    }
    if (savedPosition) {
      return savedPosition;
    } else {
      // vue2.0  x  y  控制
      return { x: 0, y: 0 };
    }
  },
});

export default router;
